import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { CountryInfo } from "functions/src/models/country.model";
import {
  NgxTurnstileComponent,
  NgxTurnstileFormsModule,
  NgxTurnstileModule,
} from "ngx-turnstile";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CfTurnstileChallengeReponse } from "../../models/cf-turnstile.model";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-cf-turnstile",
  standalone: true,
  imports: [
    CommonModule,
    NgxTurnstileModule,
    NgxTurnstileFormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: "./cf-turnstile.component.html",
  styleUrl: "./cf-turnstile.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CfTurnstileComponent implements OnInit {
  @ViewChild("turnstile") turnstile: NgxTurnstileComponent;
  @Output() onProcessed = new EventEmitter<CfTurnstileChallengeReponse>();
  @Output() onReset = new EventEmitter<void>();
  @Input() action: string;
  siteKey = environment.cfTurnstileSiteKey;
  userCountry$: Observable<CountryInfo>;

  private readonly userService = inject(UserService);

  ngOnInit() {
    this.userCountry$ = this.userService.userCountryWatch$;
  }

  resolved(token) {
    if (!token) {
      return;
    }
    console.log("token", token);
    this.onProcessed.emit({ success: true, data: token });
  }

  errored(error) {
    this.onProcessed.emit({ success: false, error: error });
  }

  reset() {
    this.turnstile.reset();
    this.onReset.emit();
  }
}
