// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-bubble {
  margin-bottom: 24px;
  margin-right: 12px;
}
.chat-bubble.shift {
  margin-bottom: 72px;
}

.fb-chat-icon img,
.line-chat-icon img {
  width: 32px;
}

.line-chat-icon {
  --background: var(--ion-line-chat-color, #41c50a);
  --background-activated: var(--ion-line-chat-color, #41c50a);
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,kBAAA;AACF;AACE;EACE,mBAAA;AACJ;;AAKE;;EACE,WAAA;AADJ;;AAKA;EACE,iDAAA;EACA,2DAAA;AAFF","sourcesContent":[".chat-bubble{\n  margin-bottom: 24px;\n  margin-right: 12px;;\n\n  &.shift{\n    margin-bottom: 72px;\n  }\n}\n\n.fb-chat-icon,\n.line-chat-icon {\n  img {\n    width: 32px;\n  }\n}\n\n.line-chat-icon {\n  --background: var(--ion-line-chat-color, #41c50a);\n  --background-activated: var(--ion-line-chat-color, #41c50a);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
