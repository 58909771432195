import { Component, Input, OnInit } from "@angular/core";
import { CountryCodeEnum } from "../../models/country.model";
import { PopoverService } from "../../services/popover.service";

@Component({
  selector: "app-line-contact-modal",
  templateUrl: "./line-contact-modal.component.html",
  styleUrls: ["./line-contact-modal.component.scss"],
})
export class LineContactModalComponent implements OnInit {
  @Input() action: string;
  @Input() country: CountryCodeEnum;

  constructor(private popoverService: PopoverService) {}

  ngOnInit() {}

  chat(): void {
    this.popoverService.dismissPopover({ action: "chat" });
  }
}
