import { Component, Input, OnInit } from "@angular/core";

import { FB_CHAT_LINK_PH, LINE_CHAT_LINK } from "../../consts";
import { CountryCodeEnum } from "../../models/country.model";
import { PopoverService } from "../../services/popover.service";
import { getSupportChatLink, openLink } from "../../utils/helpers";

@Component({
  selector: "app-account-deleted-popup",
  templateUrl: "./account-deleted-popup.component.html",
  styleUrls: ["./account-deleted-popup.component.scss"],
})
export class AccountDeletedPopupComponent implements OnInit {
  @Input()
  country: CountryCodeEnum;

  constructor(private popoverService: PopoverService) {}

  ngOnInit() {}

  close(): void {
    this.popoverService.dismissPopover({});
  }

  contactUs(): void {
    const url = getSupportChatLink(this.country);
    openLink(url);
    this.close();
  }
}
