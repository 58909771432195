import { ValidationMessage } from "../../models/validation-message.model";

const birthDateValidationMessage = [
  { type: "required", message: "ERRORS.REQUIRED" },
  { type: "invalidDate", message: "INFO_CROSS_CHECK.ERRORS.INVALID_DATE" },
  { type: "dateInFuture", message: "INFO_CROSS_CHECK.ERRORS.FUTURE_DATE" },
  {
    type: "invalidDay",
    message: "INFO_CROSS_CHECK.ERRORS.INVALID_DAY_RANGE",
  },
  {
    type: "invalidMonth",
    message: "INFO_CROSS_CHECK.ERRORS.INVALID_MONTH_RANGE",
  },
  { type: "invalidYear", message: "INFO_CROSS_CHECK.ERRORS.INVALID_YEAR" },
];

export const FORM_VALIDATION: {
  [controlName: string]: ValidationMessage[];
} = {
  pin: [
    { type: "required", message: "ERRORS.PIN.PIN_REQUIRED" },
    { type: "minlength", message: "ERRORS.PIN.PIN_MIN_LENGTH" },
    { type: "maxlength", message: "ERRORS.PIN.PIN_MIN_LENGTH" },
    { type: "pattern", message: "ERRORS.PIN.PIN_DIGITS" },
    { type: "incorrectPin", message: "ERRORS.PIN.WRONG_PIN" },
    { type: "commonlyUsedPin", message: "EASILY_GUESSED_PIN" },
  ],
  currentPin: [
    { type: "required", message: "ERRORS.PIN.PIN_REQUIRED" },
    { type: "minlength", message: "ERRORS.PIN.PIN_MIN_LENGTH" },
    { type: "maxlength", message: "ERRORS.PIN.PIN_MIN_LENGTH" },
    { type: "pattern", message: "ERRORS.PIN.PIN_DIGITS" },
    { type: "incorrectPin", message: "ERRORS.PIN.WRONG_PIN" },
  ],
  newPin: [
    { type: "required", message: "ERRORS.PIN.PIN_REQUIRED" },
    { type: "minlength", message: "ERRORS.PIN.PIN_MIN_LENGTH" },
    { type: "maxlength", message: "ERRORS.PIN.PIN_MIN_LENGTH" },
    { type: "pattern", message: "ERRORS.PIN.PIN_DIGITS" },
    { type: "commonlyUsedPin", message: "EASILY_GUESSED_PIN" },
  ],
  confirmPin: [{ type: "pinNotMatch", message: "ERRORS.PIN.PIN_NOT_MATCH" }],
  full_name_en: [{ type: "required", message: "ERRORS.FULL_NAME" }],
  full_name_th: [{ type: "required", message: "ERRORS.FULL_NAME" }],
  birth_date: [{ type: "required", message: "ERRORS.BIRTH_DATE" }],
  id_card_number: [{ type: "required", message: "ERRORS.ID_CARD_NUMBER" }],
  expiry_date: [{ type: "required", message: "ERRORS.EXPIRY_DATE" }],
  streetAddress: [{ type: "required", message: "ERRORS.STREET_ADDRESS" }],
  postalCode: [
    { type: "required", message: "ERRORS.POSTAL_CODE.POSTAL_CODE_REQUIRED" },
    { type: "minlength", message: "ERRORS.POSTAL_CODE.POSTAL_CODE_LENGTH" },
    { type: "maxlength", message: "ERRORS.POSTAL_CODE.POSTAL_CODE_LENGTH" },
    { type: "pattern", message: "ERRORS.POSTAL_CODE.POSTAL_CODE_DIGITS" },
  ],
  subDistrict: [{ type: "required", message: "ERRORS.SUB_DISTRICT" }],
  district: [{ type: "required", message: "ERRORS.DISTRICT" }],
  province: [{ type: "required", message: "ERRORS.PROVINCE" }],
  tosAgreement: [{ type: "requiredTrue", message: "ERRORS.CHECKBOX" }],
  bankAccount: [
    { type: "required", message: "ERRORS.BANK_ACCOUNT" },
    { type: "pattern", message: "ERRORS.BANK_ACCOUNT_PATTERN" },
  ],
  employerType: [{ type: "required", message: "ERRORS.TYPE_OF_EMPLOYER" }],
  businessType: [{ type: "required", message: "ERRORS.BUSINESS_TYPE" }],
  position: [{ type: "required", message: "ERRORS.POSITION_DEPARTMENT" }],
  careerField: [{ type: "requiredTrue", message: "ERRORS.CAREER_FIELD" }],
  occupation: [{ type: "requiredTrue", message: "ERRORS.OCCUPATION" }],
  averageSalary: [
    { type: "requiredTrue", message: "ERRORS.AVERAGE_SALARY" },
    { type: "min", message: "ERRORS.AVERAGE_SALARY_AMOUNT" },
  ],
  payrollFrequency: [
    { type: "requiredTrue", message: "ERRORS.PAYROLL_FREQUENCY" },
  ],
  payrollDate: [{ type: "requiredTrue", message: "ERRORS.PAYROLL_DATE" }],
  paydaySunday: [{ type: "requiredTrue", message: "ERRORS.PAYROLL_ON_SUNDAY" }],
  paydayPublicHoliday: [
    { type: "requiredTrue", message: "ERRORS.PAYROLL_ON_PUBLIC_HOLIDAY" },
  ],
  paydayPublicHolidayOther: [
    { type: "requiredTrue", message: "ERRORS.PAYDAY_OTHER" },
  ],
  paydaySundayOther: [{ type: "requiredTrue", message: "ERRORS.PAYDAY_OTHER" }],
  paydaySaturdayOther: [
    { type: "requiredTrue", message: "ERRORS.PAYDAY_OTHER" },
  ],
  cardNumber: [
    { type: "required", message: "ERRORS.CARD_NUMBER.CARD_NUMBER_REQUIRED" },
    { type: "minlength", message: "ERRORS.CARD_NUMBER.CARD_NUMBER_INVALID" },
    { type: "maxlength", message: "ERRORS.CARD_NUMBER.CARD_NUMBER_INVALID" },
  ],
  last4: [
    { type: "pattern", message: "ERRORS.LAST4.PATTERN" },
  ],
  expiryDate: [
    ...birthDateValidationMessage,
    { type: "minlength", message: "ERRORS.EXPIRY_DATE" },
    { type: "maxlength", message: "ERRORS.EXPIRY_DATE" },
    {
      type: "invalidMinExpirationDate",
      message: "INFO_CROSS_CHECK.ERRORS.MIN_EXPIRATION_DATE",
    },
    {
      type: "invalidMaxExpirationDate",
      message: "INFO_CROSS_CHECK.ERRORS.MAX_EXPIRATION_DATE",
    },
  ],
  birthDate: [
    ...birthDateValidationMessage,
    { type: "ageRange", message: "INFO_CROSS_CHECK.ERRORS.AGE_RANGE" },
  ],
  insuranceDob: [
    ...birthDateValidationMessage,
    { type: "ageRange", message: "TVI.MANAGEMENT.ERRORS.AGE_RANGE" },
  ],
  cvvNumber: [
    { type: "required", message: "ERRORS.CCV_NUMBER.CVV_REQUIRED" },
    { type: "minlength", message: "ERRORS.CCV_NUMBER.CVV_INVALID" },
    { type: "maxlength", message: "ERRORS.CCV_NUMBER.CVV_INVALID" },
  ],
  cardHolderName: [{ type: "required", message: "ERRORS.CARD_HOLDER_NAME" }],
  bank: [{ type: "requiredTrue", message: "ERRORS.BANK" }],
  employerName: [{ type: "required", message: "ERRORS.EMPLOYER_NAME" }],
  employerPhoneNumber: [
    { type: "required", message: "ERRORS.EMPLOYER_PHONE_NUMBER_REQUIRED" },
    { type: "pattern", message: "ERRORS.EMPLOYER_PHONE_NUMBER_INVALID" },
  ],
  email: [
    { type: "email", message: "ERRORS.INVALID_EMAIL" },
    { type: "required", message: "ERRORS.REQUIRED" },
    { type: "pattern", message: "ERRORS.INVALID_EMAIL" },
  ],
  phone: [
    { type: "pattern", message: "ERRORS.INVALID_PHONE" },
    { type: "required", message: "ERRORS.REQUIRED" },
  ],
  fullName: [{ type: "required", message: "ERRORS.FULL_NAME" }],
  fullNameTh: [
    { type: "required", message: "INFO_CROSS_CHECK.ERRORS.NAME_TH" },
    { type: "minlength", message: "INFO_CROSS_CHECK.ERRORS.NAME_TH" },
    { type: "pattern", message: "INFO_CROSS_CHECK.ERRORS.NAME_THAI" },
    {
      type: "thaiStringPattern",
      message: "INFO_CROSS_CHECK.ERRORS.NAME_THAI",
    },
  ],
  fullNameEn: [
    { type: "required", message: "INFO_CROSS_CHECK.ERRORS.NAME_EN" },
    { type: "minlength", message: "INFO_CROSS_CHECK.ERRORS.NAME_EN" },
    { type: "pattern", message: "INFO_CROSS_CHECK.ERRORS.NAME_ENGLISH" },
    {
      type: "englishStringPattern",
      message: "INFO_CROSS_CHECK.ERRORS.NAME_ENGLISH",
    },
  ],
  idCardNumber: [
    { type: "required", message: "INFO_CROSS_CHECK.ERRORS.ID_NUMBER" },
  ],
  walletPhoneNumber: [
    { type: "pattern", message: "ERRORS.INVALID_PHONE" },
    { type: "required", message: "ERRORS.REQUIRED" },
  ],
  maritalStatus: [{ type: "required", message: "ERRORS.MARITAL_STATUS" }],
  educationLevel: [{ type: "required", message: "ERRORS.EDUCATION_LEVEL" }],
  housingStatus: [{ type: "required", message: "ERRORS.HOUSING_STATUS" }],
  emergencyContactName: [
    { type: "required", message: "ERRORS.EMERGENCY_CONTACT_NAME" },
  ],
  emergencyContactNumber: [
    { type: "pattern", message: "ERRORS.INVALID_PHONE" },
    { type: "required", message: "ERRORS.REQUIRED" },
  ],
  deletedReasonInput: [
    { type: "required", message: "DELETE_ACCOUNT.CUSTOM_DELETION_REASON_REQUIRED" },
  ]
};
