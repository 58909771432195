import { Component, OnInit, isDevMode } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { COUNTRIES } from "../../consts";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-country-select",
  templateUrl: "./country-select.component.html",
  styleUrls: ["./country-select.component.scss"],
})
export class CountrySelectComponent implements OnInit {
  canContinue: boolean;
  countries: any[];

  constructor(private modalController: ModalController, private userService: UserService) {
    this.canContinue = false;
    this.countries = COUNTRIES.filter(
      (country) => this.userService.hasBetaAccess(country.id)
    ).map((country) => ({
      ...country,
      selected: false,
    }));
  }

  ngOnInit() {}

  countrySelected(index: number) {
    if (!this.canContinue) {
      this.canContinue = !this.canContinue;
    }

    if (this.countries[index].selected) {
      return;
    }

    this.countries.forEach((country) => (country.selected = false));
    this.countries[index].selected = !this.countries[index].selected;
  }

  dismissModal() {
    const selectedCountry = this.countries.find((country) => country.selected);
    if (selectedCountry) {
      this.modalController.dismiss({
        code: selectedCountry.id,
      });
    }
  }
}
