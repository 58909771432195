import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AngularFireAuth } from "@angular/fire/compat/auth";

import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";

import { PopoverOptions } from "@ionic/angular";
import { REQUEST_API } from "../consts";

import { StorageService } from "./storage.service";
import { PopoverService } from "./popover.service";

import { LogoutConfirmationComponent } from "src/app/main/pages/profile/components/logout-confirmation/logout-confirmation.component";
import { LoggerService } from "./logger.service";
import { LoginOtpResponse } from "../models/login.model";
import { UserOtpResponse } from "../models/user.model";
import { AccountExistsPopupComponent } from "../components/account-exists-popup/account-exists-popup.component";
import { CookieService } from "ngx-cookie-service";
import { AccountDoesNotExistPopupComponent } from "../components/account-does-not-exist-popup/account-does-not-exist.component";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  redirectUrl: string;

  constructor(
    private angularFireAuth: AngularFireAuth,
    private httpClient: HttpClient,
    private storageService: StorageService,
    private popoverService: PopoverService,
    private loggerService: LoggerService,
    private cookieService: CookieService
  ) {}

  signInWithCustomToken(token: string) {
    return this.angularFireAuth.signInWithCustomToken(token);
  }

  getFirebaseCurrentUser() {
    return this.angularFireAuth.currentUser;
  }

  getFireAuthUser() {
    return this.angularFireAuth.user.pipe(
      catchError((error) => {
        this.loggerService.logError(error);
        return of(null);
      })
    );
  }

  async logout() {
    await this.storageService.removeAll();
    this.loggerService.updateSentryScope({});
    return this.angularFireAuth.signOut();
  }

  getIdTokenResult() {
    return this.angularFireAuth.idTokenResult.pipe(
      catchError((error) => {
        this.loggerService.logError(error);
        return of(null);
      })
    );
  }

  sendOtpPhone(
    userPhone: string,
    cfTurnstileToken: string
  ): Observable<LoginOtpResponse> {
    return this.httpClient.post<LoginOtpResponse>(
      `${REQUEST_API}/auth/login-phone-send-otp`,
      { userPhone },
      {
        headers: {
          hideError: "true",
          "cf-turnstile-response": cfTurnstileToken,
        },
      }
    );
  }

  confirmOtpPhone(
    otpCode: string,
    userPhone: string,
    cfTurnstileToken: string
  ): Observable<UserOtpResponse> {
    return this.httpClient.post<UserOtpResponse>(
      `${REQUEST_API}/auth/login-phone-confirm-otp`,
      { otpCode, userPhone },
      {
        headers: {
          hideError: "true",
          "cf-turnstile-response": cfTurnstileToken,
        },
      }
    );
  }

  sendOtpEmail(
    userEmail: string,
    cfTurnstileToken: string
  ): Observable<LoginOtpResponse> {
    return this.httpClient.post<LoginOtpResponse>(
      `${REQUEST_API}/auth/login-email-send-otp`,
      { userEmail },
      {
        headers: {
          hideError: "true",
          "cf-turnstile-response": cfTurnstileToken,
        },
      }
    );
  }

  confirmOtpEmail(
    otpCode: string,
    userEmail: string,
    cfTurnstileToken: string
  ): Observable<UserOtpResponse> {
    return this.httpClient.post<UserOtpResponse>(
      `${REQUEST_API}/auth/login-email-confirm-otp`,
      { otpCode, userEmail },
      {
        headers: {
          hideError: "true",
          "cf-turnstile-response": cfTurnstileToken,
        },
      }
    );
  }

  async showLogoutConfirmation(): Promise<{ action: string }> {
    const options: PopoverOptions = {
      component: LogoutConfirmationComponent,
      backdropDismiss: true,
      showBackdrop: true,
      animated: true,
      alignment: "center",
      cssClass: "logout-confirmation-popup",
    };

    const logoutConfirmationPopup = await this.popoverService.createPopover(
      options
    );

    await logoutConfirmationPopup.present();

    const { data } = await logoutConfirmationPopup.onDidDismiss();
    return data as { action: string };
  }

  async showAccountExistsPopover(method?: string): Promise<{ action: string }> {
    const options: PopoverOptions = {
      component: AccountExistsPopupComponent,
      backdropDismiss: true,
      showBackdrop: true,
      animated: true,
      alignment: "center",
      cssClass: "account-exists-popup",
      componentProps: { method },
    };

    const accountExistsPopup = await this.popoverService.createPopover(options);
    accountExistsPopup.present();

    const { data } = await accountExistsPopup.onDidDismiss();
    return data as { action: string };
  }

  async showAccountDoesNotExistPopover(method: string): Promise<void> {
    const options: PopoverOptions = {
      component: AccountDoesNotExistPopupComponent,
      backdropDismiss: true,
      showBackdrop: true,
      animated: true,
      alignment: "center",
      cssClass: "account-does-not-exist-popup",
      componentProps: { method },
    };

    const accountDoesNotExist = await this.popoverService.createPopover(
      options
    );

    await accountDoesNotExist.present();
  }
}
