import { Injectable } from "@angular/core";
import { ModalController, NavController, PopoverOptions } from "@ionic/angular";
import { AccountDeletedPopupComponent } from "../components/account-deleted-popup/account-deleted-popup.component";

import { DeletionConfirmationComponent } from "../components/deletion-confirmation/deletion-confirmation.component";
import { HeaderActionsComponent } from "../components/header-actions/header-actions.component";
import { CountryCodeEnum } from "../models/country.model";
import { AuthService } from "./auth.service";
import { PopoverService } from "./popover.service";
import { CookieConsentService } from "./cookie-consent.service";

@Injectable({
  providedIn: "root",
})
export class AccountDeletionService {
  constructor(
    private modalController: ModalController,
    private popoverService: PopoverService,
    private navController: NavController,
    private authService: AuthService,
    private cookieConsentService: CookieConsentService
  ) {}

  async showAccountDeletedPopover(country?: CountryCodeEnum): Promise<void> {
    const options: PopoverOptions = {
      component: AccountDeletedPopupComponent,
      backdropDismiss: true,
      showBackdrop: true,
      animated: true,
      alignment: "center",
      cssClass: "account-deteted-popup",
      componentProps: { country },
    };

    const accountDeletedPopup = await this.popoverService.createPopover(
      options
    );

    await accountDeletedPopup.present();
  }

  async showDeleteConfirmationModal(contactUsLink?: string): Promise<void> {
    const confirmationModal = await this.modalController.create({
      component: DeletionConfirmationComponent,
      backdropDismiss: false,
      mode: "ios",
      componentProps: { contactUsLink },
    });

    confirmationModal.present();

    const { data } = await confirmationModal.onDidDismiss();
    if (data && data.action && data.action === "delete") {
      this.navController.navigateRoot("/delete-account");
    }
  }

  async showHeaderActionsPopover(event): Promise<void> {
    const options: PopoverOptions = {
      component: HeaderActionsComponent,
      backdropDismiss: true,
      showBackdrop: true,
      animated: true,
      side: "bottom",
      alignment: "end",
      reference: "event",
      event,
      arrow: false,
      cssClass: "action-selection-cont",
    };

    const restrictionPopup = await this.popoverService.createPopover(options);

    await restrictionPopup.present();

    const { data } = await restrictionPopup.onDidDismiss();

    if (data && data.action === "deleteAccount") {
      this.showDeleteConfirmationModal();
    }

    if (data && data.action === "logout") {
      await this.authService.logout();
      this.navController.navigateRoot([`onboarding-intro`]);
    }

    if (data && data.action === "cookieConsent") {
      this.cookieConsentService.showCookieSettings();
    }
  }
}
