import { Bank, LocalBank } from "../models/bank.model";

import {
  ATM_CARD_BANK_IDS,
  BANKS,
  BANKS_PH,
  BANKS_PENDING_PH,
  LINK_ACCOUNT_BANKS,
  LINK_BANK_TYPE,
  TEMPORARILY_DISABLED_BANKS,
  UPLOAD_SCREENSHOT_BANKS,
  BANKS_PENDING_TH,
  BANKS_ID,
  BANKS_PENDING_ID,
} from "../consts";
import { CountryCodeEnum } from "../models/country.model";

class BankUtil {
  getAllBanks(country?: CountryCodeEnum, showPendingBanks?: boolean): Bank[] {
    let banks = this.getBanks(country);

    if (showPendingBanks) {
      const pendingBanks = this.getPendingBanks(country);
      banks = banks.concat(pendingBanks);
    }

    return banks;
  }

  getBankByName(bankName: string, country?: CountryCodeEnum): Bank {
    const allBanks = this.getAllBanksByCountry(country);
    return allBanks.find((bank) => bank.name === bankName);
  }

  getBankById(bankId: string, country?: CountryCodeEnum): Bank {
    const allBanks = this.getAllBanksByCountry(country);
    return allBanks.find((bank) => bank.id === bankId);
  }

  getBankName(bankId: string, country?: CountryCodeEnum): string {
    const allBanks = this.getAllBanksByCountry(country);
    const bank = allBanks.find((bank) => bank.id === bankId);

    return bank?.name;
  }

  getLinkBankType(bank: LocalBank | Bank): LINK_BANK_TYPE {
    return this.requiresLinkingAccount(bank.id)
      ? LINK_BANK_TYPE.linkAccount
      : LINK_BANK_TYPE.linkCard;
  }

  hasATM(bankId: string): boolean {
    return ATM_CARD_BANK_IDS.includes(bankId);
  }

  isBankDisabled(bankId: string): boolean {
    return TEMPORARILY_DISABLED_BANKS.includes(bankId);
  }

  requiresStatementUpload(bankId: string): boolean {
    return UPLOAD_SCREENSHOT_BANKS.includes(bankId);
  }

  requiresLinkingAccount(bankId: string): boolean {
    return LINK_ACCOUNT_BANKS.includes(bankId);
  }

  isWalletAccount(bankId: string): boolean {
    if (!bankId) {
      return false;
    }

    return BANKS_PH.get(bankId)?.isWallet;
  }

  private getBanks(country?: CountryCodeEnum): Bank[] {
    switch (country) {
      case CountryCodeEnum.ph:
        return [...BANKS_PH.values()];
      case CountryCodeEnum.id:
        return [...BANKS_ID.values()];
      default:
        return [...BANKS.values()];
    }
  }

  private getPendingBanks(country?: CountryCodeEnum): Bank[] {
    switch (country) {
      case CountryCodeEnum.ph:
        return [...BANKS_PENDING_PH.values()];
      case CountryCodeEnum.id:
        return [...BANKS_PENDING_ID.values()];
      default:
        return [...BANKS_PENDING_TH.values()];
    }
  }

  private getAllBanksByCountry(country?: CountryCodeEnum): Bank[] {
    return [...this.getBanks(country), ...this.getPendingBanks(country)];
  }
}

export const bankUtil = new BankUtil();
