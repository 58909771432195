import { NgModule } from "@angular/core";
import { CommonModule, DecimalPipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";
import { TranslateModule, TranslatePipe } from "@ngx-translate/core";
import { ClipboardModule } from "ngx-clipboard";

import { ValidationErrorComponent } from "./components/validation-error/validation-error.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { UploadProgressComponent } from "./components/upload-progress/upload-progress.component";
import { StepperButtonComponent } from "./components/stepper-button/stepper-button.component";
import { VerificationStepComponent } from "./components/verification-step/verification-step.component";
import { SvgIconComponent } from "./components/svg-icon/svg-icon.component";

import { UrlSanitize } from "./pipes/url-sanitize.pipe";
import { ReadMoreComponent } from "./components/read-more/read-more.component";
import { LineContactModalComponent } from "./components/line-contact-modal/line-contact-modal.component";
import { PinToggleComponent } from "./components/pin-toggle/pin-toggle.component";
import { TransactionComponent } from "./components/transaction/transaction.component";
import { QuoteAlertBannerComponent } from "./components/quote-alert-banner/quote-alert-banner.component";
import { CustomCalendarModalComponent } from "./components/custom-calendar-modal/custom-calendar-modal.component";
import { NumberOnlyInputDirective } from "./directives/number-only-input.directive";
import { RejectionMessageComponent } from "./components/rejection-message/rejection-message.component";
import { CancelVerificationComponent } from "./components/cancel-verification/cancel-verification.component";
import { BankListModalComponent } from "./components/bank-list-modal/bank-list-modal.component";
import { FeatureExplainerBannerComponent } from "./components/feature-explainer-banner/feature-explainer-banner.component";
import { FilterPillComponent } from "./components/filter-pill/filter-pill.component";
import { RedirectConfirmationModalComponent } from "./components/redirect-confirmation-modal/redirect-confirmation-modal.component";
import { MembershipStatusComponent } from "./components/membership-status/membership-status.component";
import { MembershipSignUpModalComponent } from "./components/membership-sign-up-modal/membership-sign-up-modal.component";
import { CancelMembershipConfirmationComponent } from "./components/cancel-membership-confirmation/cancel-membership-confirmation.component";
import { NoTransactionComponent } from "./components/no-transaction/no-transaction.component";
import { PopoverIonHeaderComponent } from "./components/popover-ion-header/popover-ion-header.component";
import { DeletionConfirmationComponent } from "./components/deletion-confirmation/deletion-confirmation.component";
import { HeaderActionsComponent } from "./components/header-actions/header-actions.component";
import { AccountDeletedPopupComponent } from "./components/account-deleted-popup/account-deleted-popup.component";
import { AccountExistsPopupComponent } from "./components/account-exists-popup/account-exists-popup.component";
import { AccountDoesNotExistPopupComponent } from "./components/account-does-not-exist-popup/account-does-not-exist.component";
import { DigitOnlyDirective } from "./directives/digit-only.directive";
import { ConfirmSkipIdCardComponent } from "./components/confirm-skip-id-card/confirm-skip-id-card.component";
import { EntryAnimationComponent } from "./components/entry-animation/entry-animation.component";
import { LottieModule } from "ngx-lottie";
import { CountrySelectComponent } from "./components/country-select/country-select.component";
import { CurrencyStylesPipe } from "./pipes/currency-styles.pipe";
import { CurrencyStylesCompactPipe } from "./pipes/currency-styles-compact.pipe";
import { CustomCurrencyPipe } from "./pipes/custom-currency.pipe";
import { UpcomingChargesNotificationComponent } from "./components/upcoming-charges-notification/upcoming-charges-notification.component";
import { UpcomingChargesComponent } from "./components/upcoming-charges/upcoming-charges.component";
import { AdvanceUpcomingChargesNotificationComponent } from "./components/advance-upcoming-charges-notification/advance-upcoming-charges-notification.component";
import { GetMembershipBannerComponent } from "./components/get-membership-banner/get-membership-banner.component";
import { DisableNotificationPopupComponent } from "./components/disable-notification-popup/disable-notification-popup.component";
import { TrustContentComponent } from "./components/trust-content/trust-content.component";
import { CodeInputModule } from "angular-code-input";
import { CompositeTransactionComponent } from "./components/composite-transaction/composite-transaction.component";
import { TransactionStatusIconComponent } from "./components/transaction-status-icon/transaction-status-icon.component";
import { RecentTransactionsComponent } from "./components/recent-transactions/recent-transactions.component";
import { CfTurnstileComponent } from "./components/cf-turnstile/cf-turnstile.component";

@NgModule({
  declarations: [
    ValidationErrorComponent,
    LoaderComponent,
    UploadProgressComponent,
    StepperButtonComponent,
    VerificationStepComponent,
    ReadMoreComponent,
    SvgIconComponent,
    UrlSanitize,
    CurrencyStylesCompactPipe,
    CurrencyStylesPipe,
    CustomCurrencyPipe,
    LineContactModalComponent,
    PinToggleComponent,
    TransactionComponent,
    QuoteAlertBannerComponent,
    CustomCalendarModalComponent,
    NumberOnlyInputDirective,
    DigitOnlyDirective,
    RejectionMessageComponent,
    CancelVerificationComponent,
    BankListModalComponent,
    FeatureExplainerBannerComponent,
    FilterPillComponent,
    RedirectConfirmationModalComponent,
    MembershipStatusComponent,
    MembershipSignUpModalComponent,
    CancelMembershipConfirmationComponent,
    NoTransactionComponent,
    PopoverIonHeaderComponent,
    HeaderActionsComponent,
    DeletionConfirmationComponent,
    AccountDeletedPopupComponent,
    AccountExistsPopupComponent,
    AccountDoesNotExistPopupComponent,
    ConfirmSkipIdCardComponent,
    EntryAnimationComponent,
    CountrySelectComponent,
    UpcomingChargesNotificationComponent,
    UpcomingChargesComponent,
    AdvanceUpcomingChargesNotificationComponent,
    GetMembershipBannerComponent,
    DisableNotificationPopupComponent,
    TrustContentComponent,
    CompositeTransactionComponent,
    RecentTransactionsComponent,
    TransactionStatusIconComponent,
  ],
  providers: [TranslatePipe, DecimalPipe],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ClipboardModule,
    LottieModule,
    CodeInputModule,
    CfTurnstileComponent,
  ],
  exports: [
    TranslateModule,
    ReactiveFormsModule,
    ValidationErrorComponent,
    LoaderComponent,
    UploadProgressComponent,
    StepperButtonComponent,
    VerificationStepComponent,
    ReadMoreComponent,
    SvgIconComponent,
    UrlSanitize,
    CurrencyStylesCompactPipe,
    CurrencyStylesPipe,
    CustomCurrencyPipe,
    LineContactModalComponent,
    PinToggleComponent,
    TransactionComponent,
    QuoteAlertBannerComponent,
    CustomCalendarModalComponent,
    NumberOnlyInputDirective,
    DigitOnlyDirective,
    RejectionMessageComponent,
    CancelVerificationComponent,
    BankListModalComponent,
    FeatureExplainerBannerComponent,
    FilterPillComponent,
    RedirectConfirmationModalComponent,
    MembershipStatusComponent,
    MembershipSignUpModalComponent,
    CancelMembershipConfirmationComponent,
    NoTransactionComponent,
    PopoverIonHeaderComponent,
    HeaderActionsComponent,
    DeletionConfirmationComponent,
    AccountDeletedPopupComponent,
    AccountExistsPopupComponent,
    AccountDoesNotExistPopupComponent,
    ConfirmSkipIdCardComponent,
    EntryAnimationComponent,
    CountrySelectComponent,
    UpcomingChargesNotificationComponent,
    UpcomingChargesComponent,
    AdvanceUpcomingChargesNotificationComponent,
    GetMembershipBannerComponent,
    TrustContentComponent,
    DisableNotificationPopupComponent,
    CompositeTransactionComponent,
    TransactionStatusIconComponent,
    RecentTransactionsComponent,
    CodeInputModule,
    CfTurnstileComponent,
  ],
})
export class SharedModule {}
