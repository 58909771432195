import { Injectable } from "@angular/core";
import {
  GTM_EVENT_NAMES,
  GTM_FUNNEL_VERSION,
  GTM_ONBOARDING_STEPS,
  GTM_WITHDRAWAL_STEPS,
} from "../consts";

declare const dataLayer: any;

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  constructor() {}

  trackVariable(variable: any): void {
    dataLayer.push(variable);
  }

  trackStep(
    step: number,
    eventName: string,
    stepName: string,
    funnel_version: number = GTM_FUNNEL_VERSION
  ) {
    dataLayer.push({
      event: eventName,
      step,
      step_name: stepName,
      funnel_version,
    });
  }
}
